<template>
  <div class="okvir">
    <v-overlay v-if="loading"
      :absolute="absolute"
      :opacity="opacity"
      :value="overlay"
      style="z-index: 100">
      <div v-if="loading"
        class="text-center"
        style="
          position: absolute;
          z-index: 101;
          top: 50%;
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
        ">
        <v-progress-circular indeterminate
          size="86"
          color="primary">
        </v-progress-circular>
      </div>
    </v-overlay>
    <div class="resizable"
      style="text-align: center; border-radius: 25px !important">
      <v-card tile
        color="white"
        class="pb-3"
        style="border-radius: 25px !important">
        <v-card-title class="d-flex"
          style="
            vertical-align: middle;
            background-color: #1577da;

            height: 58px;
            color: white;
          ">
          <span class="text-h5"
            style="font-weight: bold">{{ title }}</span>
        </v-card-title>
        <v-form ref="primkaForm"
          @submit.prevent="submit">
          <div style="
              display: flex;
              margin-top: 15px;
              margin-left: 5px;
              margin-right: 5px;
            ">
            <v-autocomplete class="pr-2"
              hide-details
              outlined
              dense
              disabled
              label="Prodajno mjesto"
              v-model="selectedLocation"
              :items="locations"
              item-text="name"
              item-value="value"
              auto
              return-object
              @change="getWarehouses()"
              :rules="[rules.req]">
            </v-autocomplete>
            <v-autocomplete class="pr-2"
              hide-details
              outlined
              dense
              disabled
              label="Skladište"
              v-model="selectedWarehouse"
              :items="warehouses"
              item-text="name"
              item-value="value"
              return-object
              :rules="[rules.req]">
            </v-autocomplete>
            <v-autocomplete class="pr-2"
              hide-details
              dense
              outlined
              :disabled="tipDokumenta == 'knjizno_odobrenje'"
              label="Dobavljač"
              v-model="selectedDobavljac"
              :items="dobavljaci"
              item-text="name"
              item-value="value"
              return-object
              :rules="[rules.req]">
            </v-autocomplete>
            <v-autocomplete class="pr-2"
              hide-details
              dense
              outlined
              :disabled="tipDokumenta == 'knjizno_odobrenje'"
              label="Način plaćanja"
              v-model="selectedNAcinPlacanja"
              :items="nacinplacanja"
              item-text="name"
              item-value="value"
              return-object
              :rules="[rules.req]">
            </v-autocomplete>
            <!-- <label class="datepickerLabel">Datum</label> -->
            <date-picker ref="datepicker"
              :clearable="false"
              prefix-class="xmx"
              class="datetimepicker"
              v-model="invoiceDate"
              placeholder="Datum"
              type="date"
              valueType="timestamp"
              format="DD.MM.YYYY"
              :disabled-date="disableFutureDates"
              :default-value="new Date()"
              :popup-style="{ zIndex: 9999 }"
              :rules="[rules.req]"
              :append-to-body="true">
            </date-picker>
            <v-text-field v-if="tipDokumenta == 'primka'"
              class="pl-2"
              hide-details
              outlined
              :label="tipDokumenta == 'primka'
                ? 'Broj primke'
                : 'Broj knjižnog odobrenja'
                "
              dense
              v-model="brojPrimke"
              :rules="[rules.req]"></v-text-field>

            <v-text-field v-if="tipDokumenta == 'knjizno_odobrenje'"
              class="pl-2"
              hide-details
              outlined
              label="Broj knjižnog odobrenja"
              dense
              v-model="brojKnjiznog"
              :rules="[rules.req]"></v-text-field>
            <v-autocomplete class="pl-2"
              outlined
              dense
              hide-details
              :disabled="isDisableRabatChange()"
              v-model="rabatCalcType"
              :items="rabatCalcTypes"
              label="Izračun rabata"
              item-text="name"
              item-value="value" />
            <v-autocomplete class="pl-2"
              outlined
              hide-details
              dense
              v-model="porezCalcType"
              :items="porezCalcTypes"
              label="Izračun poreza"
              item-text="name"
              item-value="value"
              @change="recalculate()" />
          </div>
        </v-form>
        <!-- <v-row>
        <v-col style="align-items: left; text-align: left">
          <v-btn id="success" class="btn infoButton mt-2 mb-2" @click="addRow"
            >Dodaj artikl</v-btn
          >
        </v-col>
      </v-row> -->
        <div class="table-sm"
          ref="table"></div>
        <v-card-actions class="pa-0 ma-0 mr-5">
          <v-row>
            <v-col style="align-items: right; text-align: right">
              <v-menu top
                origin="center center"
                offset-y
                style="background-color: red">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn id="success"
                    class="btn warningButton mt-2 mb-2 mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @touchstart.stop="">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list class="custom-list">
                  <v-list-item v-if="!modal"
                    @click="showDoc"
                    @touchstart="showDoc"
                    class="btn warningButton custom-list-item mb-2">
                    <v-list-item-title>
                      <span>Pregled dokumenata</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="clearData"
                    @touchstart="clearData"
                    class="btn dangerButton custom-list-item">
                    <v-list-item-title>
                      <span>Obriši sve</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-btn id="success"
                v-if="modal"
                class="btn dangerButton mt-2 mb-2 mr-2"
                @click="closeModal()"
                @touchstart="closeModal()">Odustani</v-btn>
              <v-btn id="success"
                class="btn successButton mt-2 mb-2"
                @click="presubmit"
                @touchstart="presubmit">Spremi</v-btn>
            </v-col></v-row>
        </v-card-actions>
      </v-card>
    </div>

    <fab-menu v-if="!$vuetify.breakpoint.smAndDown"></fab-menu>
  </div>
</template>
<script>
import { TabulatorFull as Tabulator, CalcComponent } from 'tabulator-tables'
import { df, functions } from '@/plugins/firebase'
import state from '@/state'
import { Primka } from '@/modules/point-of-sale/components/warehouse/warehouse-module/models/Primka.js'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/hr.js'
import EventBus from '@/plugins/event-bus'
import rules from '@/plugins/rules'
import FabMenu from '@/modules/company/components/FabMenu'
import { clone } from '@/plugins/utils'
import { v4 as uuidv4 } from 'uuid'
import { callAssociates } from '@/api/associates'

export default {
  components: {
    DatePicker,
    FabMenu
  },
  modules: {},
  inject: ['showMsgBox', 'confirm', 'confirmClose'],
  props: {
    modal: Boolean,
    tipDokumenta: String,
    akcija: String,
    primka: {
      type: Object,
      required: true // Ovdje kažeš da je obavezno proslijediti ovaj prop
    }
  },
  computed: {
    title () {
      var title
      switch (this.tipDokumenta) {
        case 'primka':
          title = 'Editiranje primke'
          break
        case 'knjizno_odobrenje':
          switch (this.akcija) {
            case 'add':
              title = 'Knjižno odobrenje za primku ' + this.brojPrimke
              break
            case 'update':
              title = 'Editiranje knjižnog odobrenja'
              break
          }
      }

      return title
    }
  },
  data: function () {
    return {
      checkboxes: {},
      absolute: true,
      opacity: 0.46,
      overlay: true,
      loading: false,
      tableHeight: window.innerHeight - 185 + 'px',
      tableWidth: window.innerWidth - 200 + 'px',
      margin: '56px',
      rules: {
        req: rules.req()
      },
      tabulator: null, // variable to hold your table
      locations: [],
      selectedLocation: undefined,
      warehouses: [],
      selectedWarehouse: undefined,
      invoiceDate: Date.now(),
      brojPrimke: undefined,
      brojKnjiznog: undefined,
      idPrimke: undefined,
      dobavljaci: [],
      selectedDobavljac: undefined,
      selectedNAcinPlacanja: undefined,
      nacinplacanja: [
        { name: 'Virman', value: 'Virman' },
        { name: 'Gotovina', value: 'Gotovina' },
        { name: 'Ostalo', value: 'Ostalo' }
      ],
      tableData: [],
      artikli: [],
      primkaZaEdit: undefined,
      rabatCalcType: 1,
      rabatCalcTypes: [
        {
          name: 'S jediničnom cijenom',
          value: 1
        },
        {
          name: 'S ukupnom cijenom',
          value: 2
        }
      ],
      porezCalcType: 1,
      porezCalcTypes: [
        {
          name: 'Po artiklima',
          value: 1
        },
        {
          name: 'Po ukupnoj cijeni',
          value: 2
        }
      ]
    }
  },
  methods: {
    disableFutureDates (date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date > today
    },
    recalculate () {
      if (!this.tabulator) {
        return
      }

      this.tabulator.redraw(true)
    },
    isDisableRabatChange () {
      if (!this.tabulator) {
        return false
      }

      if (this.tabulator.getData().length > 0) {
        return true
      }
    },
    customHeaderFilterFunc (headerValue, rowValue, rowData, filterParams) {
      if (headerValue === null) return true
      return headerValue === rowValue
    },
    handleCheckboxChange (columnId, checked) {
      const otherColumnId =
        columnId === 'jedinicnaCijenaSPdv'
          ? 'jedinicnaCijenaBezPdv'
          : 'jedinicnaCijenaSPdv'
      // const otherCheckbox = this.checkboxes[otherColumnId]
      if (checked) {
        // otherCheckbox.checked = false
        // otherCheckbox.indeterminate = false

        // Enable editing for the current column and disable for the other
        this.tabulator.getColumn(columnId).updateDefinition({ editor: true })
        this.tabulator
          .getColumn(otherColumnId)
          .updateDefinition({ editor: false })

        // Update selectedColumn variable
        this.selectedColumn = columnId
      } else {
        // this.checkboxes[columnId].indeterminate = true

        // Reset editable status
        this.tabulator.getColumn(columnId).updateDefinition({ editor: false })
        this.tabulator
          .getColumn(otherColumnId)
          .updateDefinition({ editor: true })
      }

      // Update the checkbox states
      this.updateCheckboxStates(columnId, checked)

      this.tabulator.getColumn('rabat').updateDefinition({ editor: true })
      this.tabulator
        .getColumn('jedinicnaCijenaSRabatom')
        .updateDefinition({ editor: true })
    },
    updateCheckboxStates (columnId, checked) {
      // Update the current checkbox state
      this.checkboxes[columnId].checked = checked
      this.checkboxes[columnId].indeterminate = false

      // Update the other checkbox state
      const otherColumnId =
        columnId === 'jedinicnaCijenaSPdv'
          ? 'jedinicnaCijenaBezPdv'
          : 'jedinicnaCijenaSPdv'
      this.checkboxes[otherColumnId].checked = !checked
      this.checkboxes[otherColumnId].indeterminate = false
    },
    customCheckboxHeaderFilter (
      cell,
      onRendered,
      success,
      cancel,
      editorParams
    ) {
      const columnId = editorParams.columnId
      const checkbox = document.createElement('input')
      checkbox.type = 'checkbox'
      checkbox.indeterminate = true // Start with indeterminate state

      checkbox.addEventListener('change', () => {
        // success(checkbox.checked)
        this.handleCheckboxChange(columnId, checkbox.checked)
      })

      // Save reference to checkbox
      if (!this.checkboxes) {
        this.checkboxes = {}
      }
      this.checkboxes[columnId] = checkbox

      return checkbox
    },

    closeModal () {
      this.$emit('closeModal')
    },
    calculateHeights () {
      this.tabulator.setHeight(window.innerHeight - 185)
    },
    presubmit () {
      if (!this.$refs.primkaForm.validate()) return
      if (this.tabulator.validate() !== true) return

      this.confirm({
        title: 'Primka',
        message: 'Jeste li sigurni da želite spremiti trenutne podatke?',
        options: {
          toolbar: true,
          width: 410,
          confirmText: 'Spremi',
          cancelText: 'Odustani'
        }
      }).then(async (resp) => {
        this.confirmClose()
        if (resp) {
          this.submit()
        }
      })
    },
    submit () {
      this.loading = true

      const primka = new Primka()
      var bottomCalc = this.tabulator.getCalcResults()

      this.tabulator.getData().forEach((element) => {
        primka.setArtikl(element)
      })

      primka.setUkupnoPovratnaNaknada()

      primka.setIdFirme(state.getCurrentCompany().id)
      primka.setIdSkladista(this.selectedWarehouse.id)
      primka.setNazivSkladista(this.selectedWarehouse.name)
      primka.setDatum(this.invoiceDate)

      var brojDokumenta
      switch (this.tipDokumenta) {
        case 'primka':
          brojDokumenta = this.brojPrimke
          break
        case 'knjizno_odobrenje':
          brojDokumenta = this.brojKnjiznog
          break
      }

      primka.setBrojPrimke(brojDokumenta)
      primka.setDobavljac(this.selectedDobavljac.name)
      primka.setNacinPlacanja(this.selectedNAcinPlacanja.value)
      primka.setUkupanIznosBezPdv(bottomCalc.bottom.ukupnaCijenaBezPdv)
      primka.setUkupanIznosSPdv(bottomCalc.bottom.ukupnaCijenaSPdv)
      primka.setUkupanIznosPdv(bottomCalc.bottom.iznosPdv)
      primka.setIdLokacije(this.selectedWarehouse.location_id)
      primka.setProdajnoSkladiste(this.selectedWarehouse.selling_warehouse)
      primka.setAkcija(this.akcija)
      primka.setTipDokumenta(this.tipDokumenta)
      primka.setTipRabata(this.rabatCalcType)
      primka.setTipPoreza(this.porezCalcType)

      var newdeliveryNote = functions.httpsCallable('newdeliverynote')

      var resourceID = this.primka.id

      if (this.tipDokumenta === 'primka') {
        delete primka.povezanaPrimka
      }

      if (this.tipDokumenta === 'knjizno_odobrenje') {
        switch (this.akcija) {
          case 'add':
            this.recursiveInvert(primka)
            primka.setDatum(this.invoiceDate)
            primka.setBrojPovezanePrimke(this.brojPrimke)
            primka.setTipRabata(this.rabatCalcType)
            primka.setTipPoreza(this.porezCalcType)
            break
          case 'update':
            this.recursiveInvert(primka)
            primka.setDatum(this.invoiceDate)
            primka.setTipRabata(this.rabatCalcType)
            primka.setTipPoreza(this.porezCalcType)
            primka.setBrojPovezanePrimke(this.primka.delivery_note_number)
            resourceID = this.primka.id
        }
      }

      newdeliveryNote({
        action: {
          operation: 'set',
          entity: 'nova_primka',
          resource_id: resourceID,
          params: primka
        }
      }).then((result) => {
        if (result.data.code === 200) {
          this.loading = false
          this.showMsgBox({
            text: 'Uspješno spremljena primka.',
            actions: ['cancel'],
            cancelBtnText: this.$t('$vuetify.close'),
            color: 'error'
          })
          this.createTable()
          this.closeModal()
        } else {
          this.loading = false
          this.showMsgBox({
            text: 'Nije uspjelo spremanje primke.',
            actions: ['cancel'],
            cancelBtnText: this.$t('$vuetify.close'),
            color: 'error'
          })
        }
      })
    },
    showDoc () {
      this.$modal.show('primke')
    },
    clearData () {
      this.confirm({
        title: 'Brisanje podataka',
        message: 'Jeste li sigurni da želite obrisati upisane podatke?',
        options: {
          toolbar: true,
          width: 410,
          confirmText: 'Obriši',
          cancelText: 'Odustani'
        }
      }).then(async (resp) => {
        this.confirmClose()
        if (resp) {
          this.tabulator.setData([])
        } else {
        }
      })
    },

    recursiveInvert (obj) {
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          if (typeof obj[key] === 'number') {
            obj[key] = obj[key] * -1
          } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            this.recursiveInvert(obj[key])
          }
        }
      }
    },

    addRow () {
      if (this.tabulator.validate() !== true) return
      this.tabulator.addRow({}).then(function (row) {
        // Dobijte ćeliju prve kolone novog retka
        var cell = row.getCell('artikl')
        if (cell) {
          // Start editing the cell
          cell.edit()
        }
      })
    },
    zbroji (a, b) {
      // Pretvaranje a i b u brojeve ako su stringovi
      a = typeof a === 'string' ? a.replace(',', '.') : a
      b = typeof b === 'string' ? b.replace(',', '.') : b

      // Pretvaranje a i b u brojeve
      a = parseFloat(a) || 0
      b = parseFloat(b) || 0

      // Zbrajanje i formatiranje rezultata
      return (a + b).toFixed(2).replace('.', ',')
    },
    pomnozi (a, b) {
      // Pretvaranje a i b u brojeve ako su stringovi
      a = typeof a === 'string' ? a.replace(',', '.') : a
      b = typeof b === 'string' ? b.replace(',', '.') : b

      // Pretvaranje a i b u brojeve
      a = parseFloat(a) || 0
      b = parseFloat(b) || 0

      // Množenje i formatiranje rezultata
      const rezultat = (a * b).toFixed(2)

      // Vraćanje rezultata kao string sa zarezom umjesto točke
      return rezultat.replace('.', ',')
    },

    kalkulacijaCijene (inputdata) {
      const data = inputdata.getRow().getData()
      const update = {}

      let ukupnaCijenaBezPdv = data.kolicina * data.jedinicnaCijenaSRabatom
      let iznosPdv =
        data.kolicina * data.jedinicnaCijenaSRabatom * (data.stopaPdv / 100)
      let jedinicnaCijenaSRabatom =
        data.jedinicnaCijenaBezPdv * (1 - data.rabat / 100)
      let jedinicnaCijenaBezPdv =
        data.jedinicnaCijenaSRabatom / (1 - data.rabat / 100)

      switch (inputdata.getField()) {
        /** Kolicina */
        case 'kolicina':
          switch (this.selectedColumn) {
            case 'jedinicnaCijenaSPdv':
              jedinicnaCijenaSRabatom =
                data.jedinicnaCijenaSPdv / (1 + data.stopaPdv / 100)
              break
            case 'jedinicnaCijenaBezPdv':
              jedinicnaCijenaSRabatom =
                data.jedinicnaCijenaBezPdv * (1 - data.rabat / 100)
              break
          }

          update.jedinicnaCijenaSRabatom = jedinicnaCijenaSRabatom.toFixed(2)
          ukupnaCijenaBezPdv = inputdata.getValue() * jedinicnaCijenaSRabatom

          update.ukupnaCijenaBezPdv = ukupnaCijenaBezPdv.toFixed(2)

          /** Predporez */
          iznosPdv =
            data.kolicina * jedinicnaCijenaSRabatom * (data.stopaPdv / 100)
          update.iznosPdv = iznosPdv.toFixed(2)

          /** Cijena s PDV-om */
          if (data.jedinicnaCijenaBezPdv && data.stopaPdv) {
            update.jedinicnaCijenaSPdv = (
              data.jedinicnaCijenaSRabatom *
              (1 + data.stopaPdv / 100)
            ).toFixed(2)
          }
          /** Ukupno */
          update.ukupnaCijenaSPdv = (ukupnaCijenaBezPdv + iznosPdv).toFixed(2)
          break

        /** Nabavna cijena */
        case 'jedinicnaCijenaBezPdv':
          jedinicnaCijenaBezPdv = inputdata.getValue()

          /** Cijena */
          if (data.rabat !== '' && data.rabat !== undefined) {
            update.jedinicnaCijenaSRabatom = jedinicnaCijenaSRabatom.toFixed(2)
          } else update.jedinicnaCijenaSRabatom = jedinicnaCijenaBezPdv

          /** Cijena s PDV-om */
          if (jedinicnaCijenaBezPdv && data.stopaPdv) {
            update.jedinicnaCijenaSPdv = (
              jedinicnaCijenaSRabatom *
              (1 + data.stopaPdv / 100)
            ).toFixed(2)
          }
          /** Ukupno bez pdv */
          ukupnaCijenaBezPdv = data.kolicina * jedinicnaCijenaSRabatom
          update.ukupnaCijenaBezPdv = ukupnaCijenaBezPdv.toFixed(2)

          /** Predporez */
          iznosPdv =
            data.kolicina * jedinicnaCijenaSRabatom * (data.stopaPdv / 100)
          update.iznosPdv = iznosPdv.toFixed(2)
          /** Ukupno */
          update.ukupnaCijenaSPdv = this.zbroji(ukupnaCijenaBezPdv + iznosPdv)
          break

        /** Rabat */
        case 'rabat':
          switch (this.rabatCalcType) {
            // RADIMO S JEDINICNOM CIJENOM
            case 1: {
              switch (this.selectedColumn) {
                case 'jedinicnaCijenaSPdv':
                  jedinicnaCijenaSRabatom = parseFloat(data.jedinicnaCijenaSPdv / (1 + data.stopaPdv / 100))
                  jedinicnaCijenaSRabatom = jedinicnaCijenaSRabatom * (1 - inputdata.getValue() / 100)
                  break

                case 'jedinicnaCijenaBezPdv':
                  jedinicnaCijenaSRabatom = data.jedinicnaCijenaBezPdv * (1 - inputdata.getValue() / 100)
                  break
              }

              if (data.rabat !== '' && data.rabat !== undefined) {
                update.jedinicnaCijenaSRabatom = jedinicnaCijenaSRabatom.toFixed(2)
              } else {
                update.jedinicnaCijenaSRabatom = data.jedinicnaCijenaBezPdv
              }

              ukupnaCijenaBezPdv = this.pomnozi(
                data.kolicina,
                jedinicnaCijenaSRabatom
              )
              update.ukupnaCijenaBezPdv = ukupnaCijenaBezPdv

              /** Predporez */
              iznosPdv =
                data.kolicina * jedinicnaCijenaSRabatom * (data.stopaPdv / 100)
              update.iznosPdv = iznosPdv.toFixed(2)

              /** Ukupno */
              update.ukupnaCijenaSPdv = this.zbroji(
                ukupnaCijenaBezPdv,
                iznosPdv
              )
              break
            }

            // RADIMO S UKUPNOM CIJENOM
            case 2: {
              switch (this.selectedColumn) {
                case 'jedinicnaCijenaSPdv': {
                  var ukupnaCijenaSPdv =
                    data.jedinicnaCijenaSPdv * data.kolicina
                  ukupnaCijenaSPdv =
                    ukupnaCijenaSPdv * (1 - inputdata.getValue() / 100)

                  ukupnaCijenaBezPdv =
                    ukupnaCijenaSPdv / (1 + data.stopaPdv / 100)
                  update.ukupnaCijenaBezPdv = ukupnaCijenaBezPdv.toFixed(2)

                  jedinicnaCijenaSRabatom = ukupnaCijenaBezPdv / data.kolicina
                  break
                }

                case 'jedinicnaCijenaBezPdv': {
                  ukupnaCijenaBezPdv =
                    data.jedinicnaCijenaBezPdv * data.kolicina

                  ukupnaCijenaBezPdv =
                    ukupnaCijenaBezPdv * (1 - inputdata.getValue() / 100)
                  update.ukupnaCijenaBezPdv = ukupnaCijenaBezPdv.toFixed(2)

                  jedinicnaCijenaSRabatom = ukupnaCijenaBezPdv / data.kolicina
                  break
                }
              }

              if (
                data.rabat !== '' &&
                data.rabat !== undefined &&
                data.rabat !== '0'
              ) {
                update.jedinicnaCijenaSRabatom =
                  jedinicnaCijenaSRabatom.toFixed(2)
              } else {
                jedinicnaCijenaSRabatom = data.jedinicnaCijenaBezPdv
                update.jedinicnaCijenaSRabatom = jedinicnaCijenaSRabatom
                ukupnaCijenaBezPdv =
                  parseFloat(jedinicnaCijenaSRabatom) * data.kolicina
                update.ukupnaCijenaBezPdv = ukupnaCijenaBezPdv.toFixed(2)
              }

              /** Predporez */
              iznosPdv =
                data.kolicina *
                parseFloat(jedinicnaCijenaSRabatom) *
                (data.stopaPdv / 100)
              update.iznosPdv = iznosPdv.toFixed(2)

              /** Ukupno */
              update.ukupnaCijenaSPdv = this.zbroji(
                ukupnaCijenaBezPdv,
                iznosPdv
              )

              break
            }
          }

          break

        /** Cijena */
        case 'jedinicnaCijenaSRabatom':
          jedinicnaCijenaSRabatom = inputdata.getValue()
          jedinicnaCijenaBezPdv =
            jedinicnaCijenaSRabatom / (1 - data.rabat / 100)
          /** Nabavna cijena */
          if (data.rabat !== '' && data.rabat !== undefined) {
            update.jedinicnaCijenaBezPdv = jedinicnaCijenaBezPdv.toFixed(2)
          } else update.jedinicnaCijenaBezPdv = jedinicnaCijenaSRabatom

          /** Cijena s PDV-om */
          if (jedinicnaCijenaBezPdv && data.stopaPdv) {
            update.jedinicnaCijenaSPdv = (
              jedinicnaCijenaSRabatom *
              (1 + data.stopaPdv / 100)
            ).toFixed(2)
          }

          /** Ukupno bez pdv */
          ukupnaCijenaBezPdv = data.kolicina * data.jedinicnaCijenaSRabatom
          update.ukupnaCijenaBezPdv = ukupnaCijenaBezPdv.toFixed(2)

          /** Predporez */
          iznosPdv =
            data.kolicina *
            data.jedinicnaCijenaSRabatom *
            (data.stopaPdv / 100)
          update.iznosPdv = iznosPdv.toFixed(2)

          /** Ukupno */
          update.ukupnaCijenaSPdv = this.zbroji(ukupnaCijenaBezPdv + iznosPdv)
          break

        /** PDV(%) */
        case 'stopaPdv':
          switch (this.selectedColumn) {
            case 'jedinicnaCijenaSPdv':
              jedinicnaCijenaSRabatom = parseFloat(
                data.jedinicnaCijenaSPdv / (1 + inputdata.getValue() / 100)
              )
              /** Cijena bez PDV-a */
              update.jedinicnaCijenaBezPdv = (
                jedinicnaCijenaSRabatom /
                (1 - data.rabat / 100)
              ).toFixed(2)
              break
            case 'jedinicnaCijenaBezPdv':
              jedinicnaCijenaSRabatom =
                data.jedinicnaCijenaBezPdv * (1 - data.rabat / 100)
              /** Cijena s PDV-om */
              if (data.jedinicnaCijenaBezPdv && inputdata.getValue()) {
                update.jedinicnaCijenaSPdv = (
                  jedinicnaCijenaSRabatom *
                  (1 + inputdata.getValue() / 100)
                ).toFixed(2)
              }
              break
          }
          /** Cijena s rabatom */
          update.jedinicnaCijenaSRabatom = jedinicnaCijenaSRabatom.toFixed(2)
          /** Ukupno bez PDV-a */
          ukupnaCijenaBezPdv = data.kolicina * jedinicnaCijenaSRabatom
          update.ukupnaCijenaBezPdv = ukupnaCijenaBezPdv.toFixed(2)

          /** Predporez */
          iznosPdv =
            data.kolicina *
            jedinicnaCijenaSRabatom *
            (inputdata.getValue() / 100)
          update.iznosPdv = iznosPdv.toFixed(2)

          /** Ukupno */
          update.ukupnaCijenaSPdv = this.zbroji(ukupnaCijenaBezPdv, iznosPdv)
          break

        /** Cijena s PDV-om */
        case 'jedinicnaCijenaSPdv':
          /** Cijena */
          jedinicnaCijenaBezPdv = (data.jedinicnaCijenaSPdv / (1 + data.stopaPdv / 100)).toFixed(2)
          update.jedinicnaCijenaBezPdv = jedinicnaCijenaBezPdv
          update.jedinicnaCijenaSRabatom = jedinicnaCijenaBezPdv

          switch (this.rabatCalcType) {
            case 1:

              /** Nabavna cijena */
              if (data.rabat !== '' && data.rabat !== undefined) {
                jedinicnaCijenaSRabatom = (parseFloat(jedinicnaCijenaBezPdv) * (1 - data.rabat / 100)).toFixed(2)
                update.jedinicnaCijenaSRabatom = jedinicnaCijenaSRabatom
              }

              /** Ukupno bez pdv */
              ukupnaCijenaBezPdv = (data.kolicina * parseFloat(jedinicnaCijenaSRabatom)).toFixed(2)
              update.ukupnaCijenaBezPdv = ukupnaCijenaBezPdv

              /** Predporez */
              iznosPdv = (data.kolicina * parseFloat(jedinicnaCijenaSRabatom) * (data.stopaPdv / 100)).toFixed(2)
              update.iznosPdv = iznosPdv

              /** Ukupno */
              update.ukupnaCijenaSPdv = (parseFloat(ukupnaCijenaBezPdv) + parseFloat(iznosPdv)).toFixed(2)

              break
            case 2: {
              let ukupnaCijenaSPdv = data.jedinicnaCijenaSPdv * data.kolicina
              ukupnaCijenaSPdv = (ukupnaCijenaSPdv * (1 - data.rabat / 100)).toFixed(2)

              ukupnaCijenaBezPdv = (parseFloat(ukupnaCijenaSPdv) / (1 + data.stopaPdv / 100)).toFixed(2)
              update.ukupnaCijenaBezPdv = ukupnaCijenaBezPdv

              jedinicnaCijenaSRabatom = (parseFloat(ukupnaCijenaBezPdv) / data.kolicina).toFixed(2)
              update.jedinicnaCijenaSRabatom = jedinicnaCijenaSRabatom

              /** Predporez */
              iznosPdv = (data.kolicina * parseFloat(jedinicnaCijenaSRabatom) * (data.stopaPdv / 100)).toFixed(2)
              update.iznosPdv = iznosPdv

              /** Ukupno */
              update.ukupnaCijenaSPdv = this.zbroji(
                ukupnaCijenaBezPdv,
                parseFloat(iznosPdv)
              )

              break
            }
          }

          break
      }

      inputdata.getRow().update(update)
    },

    numberValidation (cell, num, parameters) {
      let allowZero = false
      if (parameters?.allowZero ?? false) {
        allowZero = true
      }
      if (!num || num === '' || (num === 0 && !allowZero)) return false
      var bla = num.toString().trim()
      if (bla === '') return false
      var number = bla.replace(/,/g, '.')
      number = parseFloat(number)
      if (allowZero && parseInt(Math.round(number * 100)) >= 0) return true
      if (parseInt(Math.round(number * 100)) > 0) return true
      return false
    },
    async getLocations () {
      this.showLocationSelector = false

      df.collection('locations')
        .where('id', '==', this.selectedWarehouse.location_id)
        .get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach((location) => {
              const loc = location.data()
              this.locations.push(loc)
            })
            if (this.locations.length === 1) {
              this.selectedLocation = this.locations[0]
            }
            this.locations = this.locations.sort((a, b) =>
              a.name.localeCompare(b.name)
            )
            this.$forceUpdate()
          }
        })
    },

    async getDobavljaci (customerName) {
      this.showLocationSelector = false
      var path = `/associates/${state.getCurrentCompany().id}/associate`
      var response = await callAssociates('get', path)

      if (response && response.status === 200 && response.data && response.data.associates) {
        this.hideLoader()
        Object.keys(response.data.associates).forEach((id) => {
          var dob = {
            name: response.data.associates[id].name,
            value: id,
            all: response.data.associates[id]
          }
          if (dob.name === customerName) {
            this.selectedDobavljac = dob
          }
          this.dobavljaci.push(dob)
        })
      } else if (response.status === 404) {
        this.hideLoader()
        this.associates = []
        this.showMsgBox({
          text: 'Klijenti nisu pronađeni.',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'warning'
        })
      } else {
        this.hideLoader()
        this.showMsgBox({
          text: 'Neuspjelo dohvaćanje klijenata',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
      }
    },
    async getWarehouses (whID) { },
    async getItems () {
      const query = df
        .collection(`companies/${state.getCurrentCompany().id}/items`)
        .where('status', '==', 'OK')
        .where('type', '==', 'GOODS')

      const documentSnapshots = await query.get()
      if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
        documentSnapshots.docs.forEach((it) => {
          const item = it.data()

          if (!item.has_norm) {
            if (item.warehouse_units?.units) {
              const map = item.warehouse_units.units
              const entries = Object.entries(map)
              entries.sort(([keyA, valueA], [keyB, valueB]) => {
                return (
                  (valueB.default_store === true) -
                  (valueA.default_store === true)
                )
              })
              const sortedMap = Object.fromEntries(entries)
              var unit = []
              for (const key in sortedMap) {
                unit.push({ label: map[key].storage_unit, value: map[key].id })
              }
              this.artikli.push({
                id: item.id,
                label: item.name,
                value: item.name,
                mjerneJedinice: unit,
                stopaPdv: item.tax_group_object?.pdv_rate ?? '',
                item: item
              })
            }
          }
        })

        var povratnaUnitId = uuidv4()
        this.artikli.push({
          id: '-1',
          label: 'Povratna naknada',
          value: 'Povratna naknada',
          mjerneJedinice: [{ label: 'kom', value: povratnaUnitId }],
          stopaPdv: '0',
          // cijena: '0.07',
          item: {
            id: '-1',
            name: 'Povratna naknada',
            selling_unit: 'kom',
            storage_unit: 'kom',
            warehouse_units: {
              base_unit: povratnaUnitId,
              default_sale: povratnaUnitId,
              default_store: povratnaUnitId,
              units: {
                [povratnaUnitId]: {
                  base_unit: true,
                  default_sale: true,
                  default_store: true,
                  id: povratnaUnitId,
                  is_selling_item: true,
                  storage_amount: 1000,
                  storage_unit: 'kom'
                }
              }
            }
          }
        })
      }
      this.artikli = this.artikli.sort((a, b) =>
        a.label.localeCompare(b.label)
      )
    },
    createTable () {
      var that = this

      var rowContextMenu = [
        {
          label:
            "<span class='mdi mdi-content-copy iclass btn infoButton'> Kopiraj artikl</span>",
          action: function (e, row) {
            var rowData = row.getData()
            const copiedData = JSON.parse(JSON.stringify(rowData))
            that.tabulator.addRow(copiedData)
          }
        },
        {
          label:
            "<span class='mdi mdi-delete-forever iclass btn dangerButton'> Obriši artikl</span>",
          action: function (e, row) {
            row.delete()
          }
        },
        {
          separator: true
        }
        // {
        //   label: 'Admin',
        //   menu: [
        //     {
        //       label: '<i ></i> Obriši redak',
        //       action: function (e, row) {
        //         row.delete()
        //       }
        //     },
        //     {
        //       label: '<i ></i> Disabled Option',
        //       disabled: true
        //     }
        //   ]
        // }
      ]
      this.tabulator = new Tabulator(this.$refs.table, {
        persistence: false,
        layout: 'fitColumns',
        height: this.tableHeight,
        rowContextMenu: rowContextMenu,
        rowheader: true,
        keybindings: {
          addNewRow: 'ctrl + n'
        },
        data: this.tableData, // link data to table
        reactiveData: true, // enable data reactivity
        columns: [
          {
            visible: false,
            width: 40,
            field: 'id'
          },
          {
            width: 40,
            formatter: 'rownum',
            hozAlign: 'center',
            headerSort: false,
            widthGrow: 3,
            titleFormatter: function (cell, formatterParams, onRendered) {
              const el = document.createElement('button')
              el.innerHTML = '+'
              el.classList.add('butt')
              el.classList.add('infoButton')
              el.addEventListener('click', function () {
                that.addRow()
              })

              el.addEventListener('touchstart', function (event) {
                event.preventDefault() // Sprječava dvostruki klik na mobilnim uređajima
                that.addRow()
              }, { passive: true })

              return el
            }
          },
          {
            title: 'Artikl',
            field: 'artikl',
            validator: 'required',
            editor: 'list',
            widthGrow: 3,
            editorParams: {
              values: this.artikli,
              autocomplete: 'true',
              allowEmpty: false,
              listOnEmpty: true
            },
            headerSort: false,
            cellEdited: function (cell) {
              var row = cell.getRow()
              var artiklValue = cell.getValue()

              var selectedArtikl = clone(
                this.artikli.find(
                  (item) => item.value === artiklValue && !item.id.includes('*')
                )
              )

              if (selectedArtikl) {
                row.update({ mjernaJedinica: '' }) // Resetiraj polje mjernaJedinica
                row.update({
                  mjernaJedinica: selectedArtikl.mjerneJedinice[0].value
                })
                row.update({ mjernaJedinicaId: '' }) // Resetiraj polje mjernaJedinica
                row.update({
                  mjernaJedinicaId: selectedArtikl.mjerneJedinice[0].value
                })
                row.update({ stopaPdv: '' })
                row.update({ stopaPdv: selectedArtikl.stopaPdv + '' })
                row.update({ id: selectedArtikl.id })
                row.update({ jedinicnaCijenaSPdv: selectedArtikl.cijena })
                row.update({ item: selectedArtikl.item })
                this.tabulator.redraw()
                this.tabulator.navigateRight()
              }
            }.bind(this)
          },
          {
            title: 'Količina',
            field: 'kolicina',
            hozAlign: 'right',
            mutator: function (value, data) {
              if (value) {
                return value.replace(/,/g, '.')
              } else return 0
            },
            widthGrow: 3,
            tooltip: function (e, cell, onRendered) {
              const row = cell.getRow()
              if (row instanceof CalcComponent) {
                return 'Količina: ' + cell.getValue()
              }
            },
            editor: 'input',
            editorParams: { selectContents: true },
            validator: [{ type: this.numberValidation }, 'required'],
            formatter: 'numberFormat',
            bottomCalcFormatter: 'numberFormat',
            bottomCalc: 'sum',
            bottomCalcParams: { precision: 2 },
            headerSort: false,
            cellEdited: this.kalkulacijaCijene
          },
          {
            title: 'Mj. jedinica',
            field: 'mjernaJedinica',
            validator: 'required',
            editor: 'list',
            widthGrow: 3,
            editorParams: function (cell) {
              var row = cell.getRow()
              var artiklNaziv = row.getData().artikl
              var selectedArtikl = this.artikli.find(
                (artikl) => artikl.value === artiklNaziv
              )
              if (selectedArtikl) {
                return { values: selectedArtikl.mjerneJedinice }
              } else {
                return { values: [] }
              }
            }.bind(this),
            formatter: function (cell, formatterParams, onRendered) {
              var value = cell.getValue()
              if (!value) {
                return
              }
              var row = cell.getRow()
              var artiklNaziv = row.getData().artikl.trim()

              var selectedArtikl = that.artikli.find(
                (artikl) => artikl.value.trim() === artiklNaziv
              )

              var option = selectedArtikl.mjerneJedinice.find(
                (opt) => opt.value === value
              )
              return option ? option.label : value
            },
            headerSort: false
          },
          {
            title:
              'Cijena<br><span style="color:#1fd655; font-size:10px">S PDV</span>',
            field: 'jedinicnaCijenaSPdv',
            headerFilter: this.customCheckboxHeaderFilter,
            headerFilterFunc: this.customHeaderFilterFunc,
            headerFilterParams: {
              columnId: 'jedinicnaCijenaSPdv'
            },
            widthGrow: 3,
            hozAlign: 'right',
            editorParams: { selectContents: true },
            editor: false,
            bottomCalcParams: { precision: 2 },
            validator: [{ type: this.numberValidation }],
            formatter: 'numberFormat',
            headerSort: false,
            cellEdited: this.kalkulacijaCijene,
            mutator: function (value, data) {
              if (value) {
                return value.replace(/,/g, '.')
              } else return 0
            }
          },
          {
            title:
              'Cijena<br><span style="color:red; font-size:10px">Bez PDV</span>',
            headerTooltip:
              '<b>Nabavna cijena</b><br>Cijena bez PDV-a, prije obračuna rabata',
            field: 'jedinicnaCijenaBezPdv',
            headerFilter: this.customCheckboxHeaderFilter,
            headerFilterFunc: this.customHeaderFilterFunc,
            headerFilterParams: {
              columnId: 'jedinicnaCijenaBezPdv'
            },
            widthGrow: 3,
            hozAlign: 'right',
            editor: false,
            editorParams: { selectContents: true },
            validator: [
              'required',
              {
                type: this.numberValidation,
                parameters: {
                  allowZero: true
                }
              }
            ],
            cellEdited: this.kalkulacijaCijene,
            formatter: 'numberFormat',
            headerSort: false,
            mutator: function (value, data) {
              if (value) {
                return value.replace(/,/g, '.')
              } else return 0
            }
          },
          {
            title: 'Rabat (%)',
            field: 'rabat',
            hozAlign: 'right',
            editor: false,
            widthGrow: 3,
            editorParams: { selectContents: true },
            headerSort: false,
            cellEdited: this.kalkulacijaCijene,
            formatter: 'numberFormat',
            mutator: function (value, data) {
              if (value) {
                return value.replace(/,/g, '.')
              } else return 0
            }
          },

          {
            title:
              'Cijena s rabatom<br><span style="color:red; font-size:10px">Bez PDV</span>',
            field: 'jedinicnaCijenaSRabatom',
            hozAlign: 'right',
            editor: false,
            widthGrow: 3,
            validator: 'required',
            headerSort: false,
            formatter: 'numberFormat',
            cellEdited: this.kalkulacijaCijene,
            editorParams: { selectContents: true },
            mutator: function (value, data) {
              if (value) {
                return value.replace(/,/g, '.')
              } else return 0
            }
          },
          {
            title: 'PDV (%)',
            field: 'stopaPdv',
            editor: 'list',
            hozAlign: 'right',
            widthGrow: 3,
            formatter: 'numberFormat',
            formatterParams: {
              precision: 2,
              percentage: true
            },
            tooltip: function (e, cell, onRendered) {
              const row = cell.getRow()
              if (row instanceof CalcComponent) {
                return (
                  'Povratna naknada: ' +
                  cell.getValue().replace('PN:', '').trim()
                )
              }
            },
            bottomCalc: (values, data) => {
              return this.showReturnRefund(data)
            },
            editorParams: {
              values: [
                { label: '0%', value: '0' },
                { label: '5%', value: '5' },
                { label: '13%', value: '13' },
                { label: '25%', value: '25' }
              ]
            },
            // mutateLink: ['iznosPdv', 'ukupnaCijenaSPdv'],
            cellEdited: this.kalkulacijaCijene,
            headerSort: false,
            validator: 'required'
          },

          {
            title:
              'Ukupno<br><span style="color:red; font-size:10px">Bez PDV</span>',
            field: 'ukupnaCijenaBezPdv',
            hozAlign: 'right',
            editor: true,
            validator: 'required',
            widthGrow: 3,

            bottomCalc: (values, data) => {
              return this.calculateFinalAmount(data, 'ukupnaCijenaBezPdv')
            },
            bottomCalcParams: { precision: 2 },
            formatter: 'numberFormat',
            headerSort: false,
            bottomCalcFormatter: 'numberFormat',
            editorParams: { selectContents: true },
            cellEdited: this.kalkulacijaCijene,
            tooltip: function (e, cell, onRendered) {
              const row = cell.getRow()
              if (row instanceof CalcComponent) {
                return (
                  'Ukupno <span style="color:red; font-size:10px">Bez PDV</span>: ' +
                  cell.getValue()
                )
              }
            },
            mutator: function (value, data) {
              if (value) {
                return value.replace(/,/g, '.')
              } else return 0
            }
          },

          {
            title: 'Pred porez',
            field: 'iznosPdv',
            hozAlign: 'right',
            editor: true,
            validator: 'required',
            widthGrow: 3,
            bottomCalc: (values, data) => {
              return this.calculateFinalAmount(data, 'iznosPdv')
            },
            bottomCalcParams: { precision: 2 } /*, mutator: this.iznosPdva */,
            formatter: 'numberFormat',
            headerSort: false,
            bottomCalcFormatter: 'numberFormat',
            editorParams: { selectContents: true },
            tooltip: function (e, cell, onRendered) {
              const row = cell.getRow()
              if (row instanceof CalcComponent) {
                return 'Pred porez: ' + cell.getValue()
              }
            },
            mutator: function (value, data) {
              if (value) {
                return value.replace(/,/g, '.')
              } else return 0
            }
          },

          {
            title:
              'Ukupno<br><span style="color:#1fd655; font-size:10px"> S PDV</span>',
            field: 'ukupnaCijenaSPdv',
            hozAlign: 'right',
            widthGrow: 3,
            editor: true,
            formatter: 'numberFormat',
            validator: [{ type: this.numberValidation }],
            bottomCalc: 'sum',
            bottomCalcParams: { precision: 2 },
            // , mutator: this.ukupnaCijenaSPdv
            headerSort: false,
            bottomCalcFormatter: 'numberFormat',
            editorParams: { selectContents: true },
            tooltip: function (e, cell, onRendered) {
              const row = cell.getRow()
              if (row instanceof CalcComponent) {
                return (
                  'Ukupno<span style="color:#1fd655; font-size:10px"> S PDV</span>: ' +
                  cell.getValue()
                )
              }
            },
            mutator: function (value, data) {
              if (value) {
                return value.replace(/,/g, '.')
              } else return 0
            }
          }
          // ,
          // {
          //   formatter: 'buttonCross',
          //   hozAlign: 'center',
          //   cellClick: function (e, cell) {
          //     cell.getRow().delete()
          //   // TRIGGER POST REQUEST
          //   },
          //   headerSort: false
          // }
        ]
      })

      this.tabulator.on('cellTap', function (e, cell) {
        cell.edit()
      })

      Tabulator.extendModule('keybindings', 'actions', {
        addNewRow: function () {
          // delete selected rows
          that.tabulator.addRow({}).then(function (row) {
            var cell = row.getCell('artikl')
            if (cell) {
              cell.edit()
            }
          })
        }
      })

      Tabulator.extendModule('format', 'formatters', {
        numberFormat: function (cell, formatterParams) {
          var result
          const num = cell.getValue()
          if (num) {
            const input = cell.getValue().toString().replace(',', '.')

            // Parse the input as a floating point number
            const num = parseFloat(input)

            // Check for invalid input
            if (isNaN(num)) {
              return
              // throw new Error('Invalid input')
            }

            // Handle cases where there are up to 3 decimal places
            if (input.includes('.')) {
              const decimalPart = input.split('.')[1]
              if (decimalPart.length <= 3) {
                result = num.toFixed(2).replace('.', ',')
              } else {
                result = num.toFixed(3).replace('.', ',')
              }
            } else {
              // No decimal point, so just add ",00"
              if (formatterParams.percentage) {
                return num.toFixed(2).replace('.', ',') + '%'
              }
              result = num.toFixed(2).replace('.', ',')
            }
          }

          switch (this.tipDokumenta) {
            case 'knjizno_odobrenje':
              return '-' + result
            case 'primka':
              return result
            default:
              return result
          }
        }.bind(this)
      })
    },
    showReturnRefund (data) {
      var povratnaNaknada = 0
      data.forEach((item) => {
        if (item.id === '-1' && item.stopaPdv === '0') {
          povratnaNaknada += parseFloat(item.ukupnaCijenaBezPdv)
        }
      })

      if (povratnaNaknada > 0) {
        povratnaNaknada = povratnaNaknada.toFixed(2)

        switch (this.tipDokumenta) {
          case 'knjizno_odobrenje':
            povratnaNaknada = '-' + povratnaNaknada
            break
          case 'primka':
            break
          default:
        }
      }

      return 'PN: ' + povratnaNaknada
    },
    calculateFinalAmount (data, field) {
      var iznosPdv = 0
      var ukupnaCijenaBezPdv = 0

      data = data.filter(
        (item) => !(item.id === '-1' && item.stopaPdv === '0')
      )

      switch (this.porezCalcType) {
        case 1:
          data.forEach((item) => {
            if (item.id === '-1' && item.stopaPdv === '0') {
              return
            }

            iznosPdv += parseFloat(item.iznosPdv)
            ukupnaCijenaBezPdv += parseFloat(item.ukupnaCijenaBezPdv)
          })

          if (iznosPdv > 0) {
            iznosPdv.toFixed(2)
          }

          if (ukupnaCijenaBezPdv > 0) {
            ukupnaCijenaBezPdv.toFixed(2)
          }

          break
        case 2: {
          const ukupnoSPdvPoStopi = data.reduce((acc, artikal) => {
            const stopaPdv = artikal.stopaPdv
            const ukupnaCijenaSPdv = parseFloat(artikal.ukupnaCijenaSPdv)

            if (!acc[stopaPdv]) {
              acc[stopaPdv] = 0
            }

            acc[stopaPdv] += ukupnaCijenaSPdv
            return acc
          }, {})

          const pdvInfo = Object.entries(ukupnoSPdvPoStopi).map(
            ([stopaPdv, ukupnoSPdv]) => {
              const stopa = parseFloat(stopaPdv)
              const osnovica = ukupnoSPdv / (1 + stopa / 100)
              const iznosPdv = ukupnoSPdv - osnovica

              return {
                stopaPdv: stopa,
                osnovica: osnovica.toFixed(2),
                iznosPdv: iznosPdv.toFixed(2),
                ukupnoSPdv: ukupnoSPdv.toFixed(2)
              }
            }
          )

          const ukupno = pdvInfo.reduce(
            (acc, item) => {
              acc.ukupnaCijenaBezPdv += parseFloat(item.osnovica)
              acc.ukupniPdv += parseFloat(item.iznosPdv)
              return acc
            },
            { ukupnaCijenaBezPdv: 0, ukupniPdv: 0 }
          )

          iznosPdv += ukupno.ukupniPdv
          ukupnaCijenaBezPdv += ukupno.ukupnaCijenaBezPdv

          break
        }
      }

      switch (field) {
        case 'ukupnaCijenaBezPdv':
          return ukupnaCijenaBezPdv

        case 'iznosPdv':
          return iznosPdv
      }
    }
  },

  created () {
    window.addEventListener('resize', this.calculateHeights)
  },
  beforeDestroy () {
    if (this.listeners) {
      this.detachListeners()
    }
    window.removeEventListener('resize', this.calculateHeights)
  },
  async mounted () {
    this.margin = state.getMargin()
    EventBus.$on('set-mini', () => {
      this.margin = state.getMargin()
    })
    df.doc(`warehouses/${this.primka.warehouse_id}`)
      .get()
      .then((response) => {
        this.selectedWarehouse = response.data()
        this.warehouses = [this.selectedWarehouse]
        this.getLocations(this.selectedWarehouse.location_id)
      })

    await this.getItems()
    this.getDobavljaci(this.primka.customer_name)

    this.idPrimke = this.primka.id

    this.selectedNAcinPlacanja = {
      name: this.primka.fob_description,
      value: this.primka.fob_description
    }
    this.invoiceDate = this.primka.created * 1000
    this.brojPrimke = this.primka.delivery_number

    if (this.primka.rabat_calc_type < 0) {
      this.primka.rabat_calc_type *= -1
    }

    if (this.primka.porez_calc_type < 0) {
      this.primka.porez_calc_type *= -1
    }

    this.rabatCalcType = this.primka.rabat_calc_type ?? 1
    this.porezCalcType = this.primka.porez_calc_type ?? 1

    this.createTable()

    var tableData = []
    if (Object.keys(this.primka.extended_items).length > 0) {
      Object.keys(this.primka.extended_items).forEach((id) => {
        var it = this.primka.extended_items[id]

        if (
          this.tipDokumenta === 'knjizno_odobrenje' &&
          this.akcija === 'update'
        ) {
          this.recursiveInvert(it)
          this.brojKnjiznog = this.primka.delivery_number
        }

        var itID = it.id
        if (itID.includes('*')) {
          itID = itID.split('*')[1]
        }

        var unitID = ''
        var item
        this.artikli.forEach((lagerIt) => {
          if (lagerIt.id === itID && lagerIt.mjerneJedinice.length > 0) {
            item = lagerIt.item
            lagerIt.mjerneJedinice.forEach((mj) => {
              if (mj.label === it.warehouse_data.received_amount_measure_unit) {
                unitID = mj.value
              }
            })
          }
        })

        if (it.item.name === 'Povratna naknada') {
          unitID = 'kom'
        }

        var quantity = (it.warehouse_data.quantity / 1000).toString()
        if (unitID !== '') {
          quantity = (
            it.warehouse_data.received_amount_quantity / 1000
          ).toString()
        }

        tableData.push({
          artikl: it.item.name,
          id: it.id,
          iznosPdv: (it.warehouse_data.vat_amount / 100).toString(),
          jedinicnaCijenaBezPdv: (
            it.warehouse_data.received_amount_purchase_price / 100000
          ).toString(),
          jedinicnaCijenaSPdv: (it.warehouse_data.sale_price / 100).toString(),
          kolicina: quantity.toString(),
          mjernaJedinica: unitID,
          mjernaJedinicaId: unitID,
          rabat: (it.warehouse_data.supplier_rebate / 100).toString(),
          // data.jedinicnaCijenaBezPdv * (1 - data.rabat / 100)
          jedinicnaCijenaSRabatom: (
            (it.warehouse_data.received_amount_purchase_price / 100000) *
            (1 - it.warehouse_data.supplier_rebate / 100 / 100)
          ).toString(),
          stopaPdv: (it.warehouse_data.vat / 100).toString(),
          ukupnaCijenaBezPdv: (it.warehouse_data.value / 10000).toString(),
          ukupnaCijenaSPdv: (
            it.warehouse_data.wholesale_price / 100
          ).toString(),
          item: item
        })
      })
    }

    this.tabulator.on(
      'tableBuilt',
      function () {
        this.tabulator.setData(tableData) // Postavi podatke kada je tablica izgrađena
      }.bind(this)
    )

    this.selectedLocation = this.locations.filter(
      (loc) => loc.id === this.primka.location_id
    )
  }
}
</script>
<style>
.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title {
  white-space: normal;
  text-overflow: clip;
  text-align: center;
  font-size: small;
  font-family: Arial, sans-serif !important;
  font-size: 1em;
  color: black !important;
}

.tabulator-col {
  background-color: #f2f2f2 !important;
}

.tabulator .tabulator-row .tabulator-cell {
  font-family: Arial, sans-serif !important;
  font-size: 1em;
  color: black !important;
}

.tabulator-edit-select option {
  height: 30px;
  /* Postavite željenu visinu stavki u dropdownu */
}

/* CSS medijski upiti */
.resizable {
  width: 100%;
}

/* @media (min-width: 1200px) {
  .resizable {
    width: 95%;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .resizable {
    width: 95%;
  }
}

@media (max-width: 767px) {
  .resizable {
    width: 95%;
  }
} */

.tabulator-edit-list .tabulator-edit-list-item {
  padding: 4px;
  height: 50px;
  color: #333;
  outline: none;
  vertical-align: middle;
  font-size: 18px;
  padding: 16px 0 0 10px;
}

.butt {
  border-radius: 5px;
  border: 2px solid black;
  background-color: white !important;
  color: black;
  padding: 0px 5px 0px 5px;
  font-size: 24px;
  cursor: pointer;
  font-weight: bold;
}

.iclass {
  font-size: 20px;
  /* Veličina fonta */
  height: 30px;
  /* Visina ikone */
  line-height: 30px;
  /* Postavljanje vertikalnog centriranja */
  display: inline-flex;
  /* Omogućava postavljanje dimenzija i centriranje */
  align-items: center;
  /* Vertikalno centriranje */
  justify-content: center;
  /* Horizontalno centriranje */
  transition: color 0.3s, background-color 0.3s;
  /* Glatka tranzicija za promenu boje */
  color: black;
  /* Inicijalna boja teksta */
  background-color: white;
  /* Inicijalna pozadinska boja */
  padding: 5px;
  /* Unutrašnja margina za bolji izgled */
  border-radius: 5px;
  /* Zaobljeni uglovi */
}

/* Promena boje teksta i pozadinske boje na hover */
.iclass:hover {
  color: white;
  /* Promena boje teksta na hover */
  background-color: red;
  /* Promena pozadinske boje na hover */
}
</style>

<style scoped>
.table-sm {
  margin-top: 10px;
}

.custom-list {
  padding: 0px !important;
}

.custom-list-item {
  padding-bottom: 2px !important;
  background-color: transparent;
}

.okvir {
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  justify-content: center;
  /* Vodoravno centriranje */
  align-items: center;
  /* Okomito centriranje */
  border-radius: 25px !important;
}
</style>
